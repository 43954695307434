/**
 *
 *
 * @class Clipboard
 */
class Clipboard {
  private clipboard = navigator.clipboard
  private doc = document

  /**
   *
   *
   * @param {string} s
   * @return {*}
   * @memberof Clipboard
   */
  writeText(s: string) {
    if (this.clipboard) return this.clipboard.writeText(s).then(() => true);
    else if (this.doc.execCommand) {
      const el = document.createElement('input');
      el.style.visibility = 'hidden';
      el.setAttribute('type', 'text');
      document.body.appendChild(el);
      el.select();
      let output = false;
      try {
        const successful = this.doc.execCommand('copy');
        if (successful) {
          output = true;
        } else {
          output = false;
        }
      } catch (err) {
        output = false;
      }
      document.body.removeChild(el);
      window.getSelection().removeAllRanges();
      return output;
    }
  }
}


export const copy = (str:string) => {
  const c = new Clipboard();

  return c.writeText(str);
};
