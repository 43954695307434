<template>
  <div
    v-if="!isProcessing"
    class="testing-view"
  >
    <div
      v-if="!isMicrophoneAllowedByBrowser && !isCameraAllowedByBrowser"
      class="refresh-wrapper"
    >
      <div>
        <button
          class="cursor-pointer"
          @click="requestCameraAndMicrophoneAccess(isCallTypeVideo, true)"
        >
          Please allow access to your
          <template v-if="isCallTypeVideo">
            camera and
          </template>
          microphone
        </button>
        <p>
          You may need also need to refresh this page in your browser
        </p>
      </div>
    </div>

    <div
      v-else
      class="video-wrapper"
    >
      <div class="buttons-wrapper">
        <span
          class="aspect-ratio"
          :style="{'padding-bottom': videoAspect}"
        />
        <toggle-mic
          class="overlay-mic"
          :selected-microphone-device-id="selectedMicrophoneDeviceId"
          :allowed="isMicrophoneAllowedByBrowser"
          :enabled="isMicrophoneAllowedByUser"
          :muted-remotely="false"
          :circular="true"
          @toggle="toggleMyAudio"
        />

        <toggle-video
          v-if="isCallTypeVideo && hasCamera"
          class="overlay-video"
          :selected-camera-device-id="selectedCameraDeviceId"
          :call-type="callType.id"
          :allowed="isCameraAllowedByBrowser"
          :enabled="isCameraAllowedByUser"
          :circular="true"
          @toggle="toggleMyVideo"
        />

        <toggle-settings
          class="overlay-settings"
          :enabled="showSettings"
          @toggle="toggleSettings"
        />
        <video
          ref="cameraPreview"
          class="video"
          :class="{
            'mirror': cameraMirrored,
          }"
          playsinline
        />

        <transition name="fade">
          <div
            v-if="isCallTypeVideo && !hasCamera"
            class="no-video"
          >
            <div>
              No Camera Detected
            </div>
          </div>
          <div
            v-else-if="isCallTypeVideo && hasNoVideo"
            class="no-video"
          >
            <div>
              No Video Enabled
              <button
                v-if="hasCamera"
                class="settings-btn"
                @click="showDefaultVideo()"
              >
                Use default camera
              </button>
            </div>
          </div>
          <div
            v-else-if="!isCallTypeVideo"
            class="no-video"
          >
            <div>
              Audio only Call
              <button
                class="settings-btn"
                @click="changeCallType('video')"
              >
                Change to Video call
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <transition name="settings-slide">
      <div
        v-if="showSettings"
        class="settings"
      >
        <div
          v-if="isCallTypeVideo && hasCamera"
          class="camera-settings"
        >
          <label>Camera</label>
          <select
            v-if="selectedCamera"
            id="camera"
            v-model="selectedCamera"
            :disabled="!isCameraAllowedByUser"
            @change="onCameraChange(selectedCamera)"
          >
            <option
              v-for="camera in cameraOptions()"
              :key="camera.deviceId"
              :value="camera"
            >
              {{ camera.label }}
            </option>
          </select>

          <button
            :class="['settings-btn', {'active': cameraMirrored}]"
            :disabled="!isCameraAllowedByUser"
            label="Mirror your camera"
            @click="flipCamera"
          >
            Mirror
          </button>
        </div>

        <div class="microphone-settings">
          <label>
            Microphone
            <a
              class="play-test-sound-link"
              @click.prevent="toggleTestSound"
            >
              {{ isTestSoundPlaying ? 'Stop playing' : 'Play test sound' }}
            </a>
          </label>
          <select
            v-if="selectedMicrophone"
            id="microphone"
            v-model="selectedMicrophone"
            :disabled="!isMicrophoneAllowedByUser"
            @change="onMicrophoneChange(selectedMicrophone)"
          >
            <option
              v-for="microphone in microphoneOptions()"
              :key="microphone.deviceId"
              :value="microphone"
            >
              {{ microphone.label }}
            </option>
          </select>

          <div class="microphoneVolumeBars">
            <div
              v-for="index in microphoneVolumeDivision"
              :key="index"
              class="bar"
            >
              <div
                class="barBk"
                :style="{width: volumeSplit(microphoneVolumeDivision, index)}"
              />
            </div>
          </div>
        </div>
        <div
          v-if="selectedSpeaker"
          class="speaker-settings"
        >
          <label>Speaker</label>
          <select
            v-if="selectedSpeaker"
            id="speaker"
            v-model="selectedSpeaker"
            @change="onSpeakerChange(selectedSpeaker)"
          >
            <option
              v-for="speaker in speakerOptions()"
              :key="speaker.deviceId"
              :value="speaker"
            >
              {{ speaker.label }}
            </option>
          </select>
        </div>

        <div class="quality-settings">
          <label>Quality</label>
          <select
            id="qualitySelect"
            v-model="quality"
          >
            <option
              v-for="callQuality in qualityList()"
              :key="callQuality.name"
              :value="callQuality"
            >
              {{ callQuality.name }}
            </option>
          </select>
          <button
            class="settings-btn"
            label="Test your connection speed"
            @click="testSpeed()"
          >
            Auto
          </button>
        </div>

        <!-- <button
          class="settings-btn play-test-sound"
          :disabled="isTestingCompleted"
          @click.prevent="toggleTestSound"
        >
          {{ isTestSoundPlaying ? 'Stop playing' : 'Play test sound' }}
        </button> -->

        <spinner
          v-if="isConnecting"
          class="mt-1"
        />
      </div>
    </transition>

    <audio ref="testSoundAudio">
      <source
        src="/phone-ringing.mp3"
        type="audio/mpeg"
      >
    </audio>
  </div>
</template>
<script>
import {callStore} from '@/store/__STORE_call';

import ToggleVideo from '@/components/call/actions/ToggleVideo.vue';
import ToggleMic from '@/components/call/actions/ToggleMic.vue';
import ToggleSettings from '@/components/call/actions/ToggleSettings.vue';

import EnumerateDevicesMixin from '@/mixins/EnumerateDevicesMixin';
import MonitorMicrophoneVolumeMixin from '@/mixins/MonitorMicrophoneVolumeMixin';
import QualityMixin from '@/mixins/QualityMixin';
import SpeedTestMixin from '@/mixins/SpeedTestMixin';
import Spinner from '@/components/Spinner';

export default {
  name: 'BeforeTesting',
  components: {
    ToggleMic,
    ToggleVideo,
    ToggleSettings,
    Spinner,
  },
  mixins: [
    EnumerateDevicesMixin,
    MonitorMicrophoneVolumeMixin,
    QualityMixin,
    SpeedTestMixin,
  ],
  props: {
    isConnecting: Boolean,
    retryConnecting: Boolean,
    previousRoute: {
      type: Object,
      default: () => {},
    },
    initialCallType: {
      type: String,
      default: null,
    },
  },
  data() {
    const selectedQuality = this.qualityList().find((quality) => {
      return quality.bandwidthKbps === 1000; // Medium quality - max 1000kbps bandwidth
    });

    return {
      cameraStream: null,
      microphoneStream: null,
      speakerStream: null,
      isCameraAllowedByBrowser: false,
      isMicrophoneAllowedByBrowser: false,

      isTestSoundPlaying: false,

      isCameraAllowedByUser: true,
      isMicrophoneAllowedByUser: true,

      isTestingCompleted: false,

      selectedMicrophone: {
        deviceId: null,
      },
      selectedSpeaker: {
        deviceId: null,
      },
      selectedCamera: {
        deviceId: null,
      },
      microphoneDeviceIdLocalStorageKey: 'microphoneDeviceIdKey',
      speakerDeviceIdLocalStorageKey: 'speakerDeviceIdKey',
      cameraDeviceIdLocalStorageKey: 'cameraDeviceIdKey',
      quality: selectedQuality,
      callType: {
        id: null,
      },
      microphoneVolumeDivision: 5,
      videoAspect: '75%',

      showSettings: false,
      isProcessing: false,
    };
  },
  computed: {
    normalizedMicrophoneVolume() {
      return Math.round(
          this.microphoneVolume / 10,
      );
    },
    isCallTypeVideo() {
      return this.callType.id === 'video';
    },
    hasNoVideo() {
      return this.isCallTypeVideo && (!this.isCameraAllowedByBrowser || !this.isCameraAllowedByUser);
    },
    hasCamera() {
      return this.cameras.length ? true : false;
    },
    selectedCameraDeviceId() {
      return this.selectedCamera ? this.selectedCamera.deviceId : null;
    },
    selectedMicrophoneDeviceId() {
      return this.selectedMicrophone ? this.selectedMicrophone.deviceId : null;
    },
    selectedSpeakerDeviceId() {
      return this.selectedSpeaker ? this.selectedSpeaker.deviceId : null;
    },
    cameraMirrored: () => callStore.cameraMirrored,
  },
  watch: {
    initialCallType() {
      this.selectCallType();
    },
    hasCamera() {
      this.$emit('has-camera', this.hasCamera);
    },
  },
  mounted() {
    navigator.mediaDevices.addEventListener('devicechange', (event) => {
      this.initHardware();
    });
    this.initHardware();
  },
  beforeDestroy() {
    this.releaseResources();
  },
  methods: {
    flipCamera() {
      callStore.mirrorCamera();
    },
    initHardware() {
      this.isProcessing = true;
      this.selectCallType();
      this.enumerateDevices(() => {
        let audioConstraints = true;
        let videoConstraints = true;
        let speakerAudioConstraints = true;

        if (this.microphoneOptions().length) {
          audioConstraints = {deviceId: this.microphoneOptions()[0].deviceId};
        }

        if (this.speakerOptions().length) {
          speakerAudioConstraints = {deviceId: this.speakerOptions()[0].deviceId};
        }

        if (this.cameraOptions().length) {
          videoConstraints = {deviceId: this.cameraOptions()[0].deviceId};
        }

        // Check if there is preselected microphone ID saved in localStorage
        const microphoneDeviceIdFromLocalStorage = localStorage.getItem(this.microphoneDeviceIdLocalStorageKey);
        if (microphoneDeviceIdFromLocalStorage) {
          const exists = !!this.microphoneOptions().find((microphone) => {
            return microphone && microphone.deviceId && microphone.deviceId === microphoneDeviceIdFromLocalStorage;
          });

          if (exists) {
            audioConstraints = {deviceId: microphoneDeviceIdFromLocalStorage};
          }
        }

        // Check if there is preselected speaker ID saved in localStorage
        const speakerDeviceIdFromLocalStorage = localStorage.getItem(this.speakerDeviceIdLocalStorageKey);
        if (speakerDeviceIdFromLocalStorage) {
          const exists = !!this.speakerOptions().find((speaker) => {
            return speaker && speaker.deviceId && speaker.deviceId === speakerDeviceIdFromLocalStorage;
          });

          if (exists) {
            speakerAudioConstraints = {deviceId: speakerDeviceIdFromLocalStorage};
          }
        }

        // Check if there is preselected camera ID saved in localStorage
        const cameraDeviceIdFromLocalStorage = localStorage.getItem(this.cameraDeviceIdLocalStorageKey);
        if (cameraDeviceIdFromLocalStorage) {
          const exists = !!this.cameraOptions().find((camera) => {
            return camera.deviceId === cameraDeviceIdFromLocalStorage;
          });

          if (exists) {
            videoConstraints = {deviceId: cameraDeviceIdFromLocalStorage};
          }
        }

        if (this.callType.id === 'video') {
          this.requestCameraAndMicrophoneAccess(videoConstraints, audioConstraints, speakerAudioConstraints);
        } else if (this.callType.id === 'audioonly') {
          this.requestMicrophoneAccess(audioConstraints);
        }

        // Loop the test sound until it's not explicitly stopped by user:
        if (this.$refs.testSoundAudio) {
          this.$refs.testSoundAudio.onended = () => {
            this.$refs.testSoundAudio.play();
          };
        }
      });
      this.testSpeed();
    },
    selectCallType() {
      this.callType = this.callTypeOptions().find((callType) => {
        return callType.id === this.initialCallType;
      });
    },
    toggleMyAudio() {
      this.isMicrophoneAllowedByUser = !this.isMicrophoneAllowedByUser;
      if (this.isMicrophoneAllowedByUser) {
        this.onMicrophoneChange(this.selectedMicrophone);
      } else {
        this.stopMicrophone();
      }
    },

    toggleMyVideo() {
      this.isCameraAllowedByUser = !this.isCameraAllowedByUser;
      if (this.isCameraAllowedByUser) {
        this.onCameraChange(this.selectedCamera);
      } else {
        this.stopCamera();
      }
    },

    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    /**
     * Releases camera and audio resources for use by other consumers.
     */
    releaseResources() {
      this.stopTestSound();
      this.stopCamera();
      this.stopMicrophone();
    },
    stopTestSound() {
      const el = this.$refs.testSoundAudio;
      if (el) {
        el.pause();
      }
    },
    volumeSplit(division, step) {
      const vol = this.microphoneVolume;
      const start = (100 / division) * step;
      const end = (100 / division) * (step + 1);
      let percentage = 0;
      if (vol <= start) {
        percentage = 0;
      }
      if (vol >= end) {
        percentage = 100;
      }
      if (vol > start && vol < end) {
        percentage = (vol - start) * division;
      }
      return percentage + 'px';
    },
    microphoneOptions() {
      const list = [];
      this.microphones.map((microphone) => {
        if (microphone.deviceId.length > 0) {
          list.push({
            deviceId: microphone.deviceId,
            label: microphone.label,
          });
        }
      });
      return list;
    },
    speakerOptions() {
      const list = [];
      this.speakers.map((speaker) => {
        if (speaker.deviceId.length > 0) {
          list.push({
            deviceId: speaker.deviceId,
            label: speaker.label,
          });
        }
      });
      return list;
    },
    cameraOptions() {
      const list = [];
      this.cameras.map((camera) => {
        if (camera.deviceId.length > 0) {
          list.push({
            deviceId: camera.deviceId,
            label: camera.label,
          });
        }
      });
      return list;
    },
    callTypeOptions() {
      return [
        {
          id: 'video',
          icon: 'ic_camera_small_grey.svg',
          name: 'Video',
        },
        {
          id: 'audioonly',
          icon: 'ic_volume_small.svg',
          name: 'Audio only',
        },
      ];
    },
    toggleTestSound() {
      const el = this.$refs.testSoundAudio;
      this.isTestSoundPlaying = !this.isTestSoundPlaying;

      if (el.paused) {
        el.play();
        return;
      }

      el.pause();
      el.currentTime = 0;
    },
    stopCamera() {
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }
    },
    stopSpeaker() {
      if (this.speakerStream) {
        this.speakerStream.getTracks().forEach((track) => track.stop());
      }
    },
    stopMicrophone() {
      if (this.microphoneStream) {
        this.microphoneStream.getTracks().forEach((track) => track.stop());
      }

      if (this.microphoneVolumeCheckInterval) {
        window.clearInterval(this.microphoneVolumeCheckInterval);
      }

      this.microphoneVolume = 0;
    },
    onMicrophoneChange(newMicrophone) {
      this.stopMicrophone();

      this.isMicrophoneAllowedByUser = true;

      this.requestMicrophoneAccess({
        deviceId: newMicrophone.deviceId,
      });
    },
    selectMicrophone(deviceId) {
      this.selectedMicrophone = this.microphoneOptions().find((microphone) => {
        return microphone.deviceId === deviceId;
      });
    },
    onSpeakerChange(newSpeaker) {
      this.stopSpeaker();

      this.isMicrophoneAllowedByUser = true;

      this.requestSpeakerAccess({
        deviceId: newSpeaker.deviceId,
      });
    },
    selectSpeaker(deviceId) {
      this.selectedSpeaker = this.speakerOptions().find((speaker) => {
        return speaker.deviceId === deviceId;
      });
    },
    selectCamera(deviceId) {
      this.selectedCamera = this.cameraOptions().find((camera) => {
        return camera.deviceId === deviceId;
      });
    },
    onCameraChange(newCamera) {
      this.stopCamera();

      this.isCameraAllowedByUser = true;

      this.requestCameraAccess({
        deviceId: newCamera.deviceId,
      });
    },
    requestCameraAndMicrophoneAccess(videoConstraints, audioConstraints, speakerAudioConstraints, callback) {
      if (this.cameras.length && this.microphones.length) {
        1;
      } else if (this.cameras.length) {
        this.requestCameraAccess(videoConstraints, callback);
        return;
      } else if (this.microphones.length) {
        this.requestMicrophoneAccess(audioConstraints, callback);
        return;
      }

      const constraints = {video: videoConstraints, audio: audioConstraints, speaker: speakerAudioConstraints};

      if (constraints.video.deviceId === null || constraints.video.deviceId === '') {
        constraints.video = false;
      }

      if (constraints.audio.deviceId === null || constraints.audio.deviceId === '') {
        constraints.audio = false;
      }
      if (constraints.speaker.deviceId === null || constraints.speaker.deviceId === '') {
        constraints.speaker = speakerAudioConstraints;
      }

      navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            this.cameraStream = new MediaStream(stream.getVideoTracks());
            this.microphoneStream = new MediaStream(stream.getAudioTracks());

            this.isCameraAllowedByBrowser = true;
            this.isMicrophoneAllowedByBrowser = true;
            this.isProcessing = false;

            this.monitorMicrophoneVolume(this.microphoneStream);

            this.enumerateDevices(() => {
              this.onCameraAccessGrantedAndEnumerated(videoConstraints, this.cameraStream);
              this.onMicrophoneAccessGrantedAndEnumerated(audioConstraints);
              this.onSpeakerAccessGrantedAndEnumerated(speakerAudioConstraints);


              if (callback) {
                callback();
              }
            });
          })
          .catch(() => {
            this.isProcessing = false;
            if (callback) {
              callback();
            }
          });
    },
    requestCameraAccess(videoConstraints, callback) {
      if (videoConstraints.deviceId === null || videoConstraints.deviceId === '') {
        return false;
      }

      videoConstraints = videoConstraints ? videoConstraints : true;

      navigator.mediaDevices.getUserMedia({video: videoConstraints})
          .then((stream) => {
            this.cameraStream = stream;
            this.isCameraAllowedByBrowser = true;
            this.isProcessing = false;

            this.enumerateDevices(() => {
              this.onCameraAccessGrantedAndEnumerated(videoConstraints, this.cameraStream);
              if (callback) {
                callback();
              }
            });
          })
          .catch(() => {
            this.isProcessing = false;
            if (callback) {
              callback();
            }
          });
    },
    requestMicrophoneAccess(audioConstraints, callback) {
      if (audioConstraints.deviceId === null || audioConstraints.deviceId === '') {
        return false;
      }

      audioConstraints = audioConstraints ? audioConstraints : true;

      navigator.mediaDevices.getUserMedia({audio: audioConstraints})
          .then((stream) => {
            this.microphoneStream = stream;
            this.isMicrophoneAllowedByBrowser = true;
            this.monitorMicrophoneVolume(stream);
            this.isProcessing = false;

            this.enumerateDevices(() => {
              this.onMicrophoneAccessGrantedAndEnumerated(audioConstraints);
              if (callback) {
                callback();
              }
            });
          })
          .catch(() => {
            this.isProcessing = false;
            if (callback) {
              callback();
            }
          });
    },
    requestSpeakerAccess(audioConstraints, callback) {
      if (audioConstraints.deviceId === null || audioConstraints.deviceId === '') {
        return false;
      }

      audioConstraints = audioConstraints ? audioConstraints : true;

      navigator.mediaDevices.getUserMedia({audio: audioConstraints})
          .then((stream) => {
            this.speakerStream = stream.clone(); // Clone the stream for the speaker
            this.monitorMicrophoneVolume(stream);
            this.isProcessing = false;

            this.enumerateDevices(() => {
              this.onSpeakerAccessGrantedAndEnumerated(audioConstraints); // Handle speaker access
              if (callback) {
                callback();
              }
            });
          })
          .catch(() => {
            this.isProcessing = false;
            if (callback) {
              callback();
            }
          });
    },
    onCameraAccessGrantedAndEnumerated(videoConstraints, stream) {
      let deviceId = videoConstraints.deviceId;
      if (!deviceId) {
        deviceId = this.cameraOptions().length ? this.cameraOptions()[0].deviceId : null;
      }

      this.selectCamera(deviceId);
      const cameraPreview = this.$refs.cameraPreview;
      cameraPreview.srcObject = stream;
      const playPromise = cameraPreview.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          this.videoAspect = cameraPreview.videoHeight / cameraPreview.videoWidth * 100 + '%';
          // Automatic playback started!
        })
            .catch((error) => {
              //
            });
      }
    },
    onMicrophoneAccessGrantedAndEnumerated(audioConstraints) {
      let deviceId = audioConstraints.deviceId;
      if (!deviceId) {
        deviceId = this.microphoneOptions().length ? this.microphoneOptions()[0].deviceId : null;
      }

      this.selectMicrophone(deviceId);
    },
    onSpeakerAccessGrantedAndEnumerated(audioConstraints) {
      let deviceId = audioConstraints.deviceId;
      if (!deviceId) {
        deviceId = this.speakerOptions().length ? this.speakerOptions()[0].deviceId : null;
      }

      this.selectSpeaker(deviceId);
    },
    onTestingComplete() {
      if (!this.isMicrophoneAllowedByBrowser && !this.isCameraAllowedByBrowser) {
        this.requestCameraAndMicrophoneAccess(true, true, () => {
          this.emitTestingComplete();
        });
        return;
      }

      this.emitTestingComplete();
    },
    emitTestingComplete() {
      localStorage.setItem(this.microphoneDeviceIdLocalStorageKey, this.selectedMicrophone.deviceId);
      localStorage.setItem(this.cameraDeviceIdLocalStorageKey, this.selectedCamera.deviceId);
      localStorage.setItem(this.speakerDeviceIdLocalStorageKey, this.selectedSpeaker.deviceId);

      this.stopTestSound();

      this.isTestSoundPlaying = false;
      this.isTestingCompleted = true;
      this.$emit('testing-complete', {
        isCameraAllowedByBrowser: this.isCameraAllowedByBrowser,
        isCameraAllowedByUser: this.isCameraAllowedByUser,
        isMicrophoneAllowedByBrowser: this.isMicrophoneAllowedByBrowser,
        isMicrophoneAllowedByUser: this.isMicrophoneAllowedByUser,
        selectedCameraDeviceId: this.selectedCamera.deviceId,
        selectedMicrophoneDeviceId: this.selectedMicrophone.deviceId,
        selectedSpeakerDeviceId: this.selectedSpeaker.deviceId,
        maximumBandwidthKbps: this.quality.bandwidthKbps,
        callType: this.callType.id,
      });
    },
    joinMeeting() {
      this.stopTestSound();
      this.isTestSoundPlaying = false;
      this.isTestingCompleted = true;
      this.$emit('continue-connect-with-pin', this.pin);
    },
    testSpeed() {
      this.speedTest((speedKbps) => {
        if (!this.isConnecting) {
          this.maximumBandwidthKbps = speedKbps;

          if (speedKbps <= 1500) {
            this.quality = this.qualityList().reverse().find((quality) => quality.bandwidthKbps && quality.bandwidthKbps <= speedKbps);
          } else {
            this.quality = this.qualityList().reverse().find((quality) => quality.id == 'best');
          }
        }
      });
    },
    changeCallType(type) {
      callStore.setCallType(type);
    },
    showDefaultVideo() {
      if (this.cameras.length) {
        this.onCameraChange(this.cameraOptions()[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  .testing-view {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 25px 0 0;

    button {
      background:#e5e5e5;
      border: none;
      border-radius: 5px;
      display: block;
      padding: 5px;

      &:hover,
      &.active {
        background: darken(#e5e5e5, 10%);
      }
    }

    .refresh-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      flex: 0 0 55%;
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      position: relative;

      &:before {
        content: "";
        display: block;
        flex: 0 1 0;
        padding-bottom: 75%;
      }

      > div {
        padding: 1rem;
        text-align: center;

        button {
          margin-bottom: 1rem;
        }
      }
    }

    .video-wrapper {
      flex: 0 0 55%;
      overflow: hidden;

      .buttons-wrapper {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        .aspect-ratio {
          display: block;
          flex: 0 1 0;
        }

        .overlay-video,
        .overlay-mic,
        .overlay-settings {
          position: absolute;
          bottom: 15px;
          z-index: 10;
        }

        .overlay-video {
          left: 65px;
        }

        .overlay-mic {
          left: 15px;
        }

        .overlay-settings {
          right: 15px;
        }
      }

      .video,
      .no-video {
        border-radius: 5px;
        display: block;
        width: 100%;
      }

      .no-video {
        align-items: center;
        background: black;
        bottom: 0;
        color: white;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;

        > div {
          text-align: center;

          button {
            margin-top:10px;
            padding: 2px 5px;
          }
        }
      }
    }
  }

  .settings {
    display: flex;
    flex-direction: column;
    flex: 0 1 45%;
    margin: 0 0 0 20px;

    > div {
      display: flex;
      flex-wrap: wrap;
      flex: 0 1;
      margin-bottom: 15px;
      width: 100%;

      label {
        display: block;
        margin-bottom: 5px;
        width: 100%;

        .play-test-sound-link {
          float: right;
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      button {
        flex: 0 0 60px;
        margin-left: 5px;
      }

      select {
        border: 1px solid #ddd;
        border-radius: 5px;
        flex: 1 1;
        padding: 5px;
        width: 100%;
      }
    }
  }

  .microphoneVolumeBars {
    display: flex;
    flex-wrap: nowrap;
    flex: 0 0 60px;
    justify-content: space-between;
    margin-left: 5px;

    .bar {
      background-color: #e5e5e5;
      border-radius: 5px;
      height: 33px;
      width: calc((60px / 5) - 3px);
      overflow: hidden;

      .barBk {
        height: 33px;
        background-color: #a9d84b;
        display: inline-block;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .testing-view {
      display: block;

      .settings {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  // Transitions
  .fade-enter-active {
    transition: all .35s;
  }
 .fade-leave-active {
    transition-delay: 1s;
    transition: all .35s;
  }
  .settings-slide-enter-active, .settings-slide-leave-active {
    transition: all .35s ease;
  }
  .settings-slide-enter, .settings-slide-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }
</style>
