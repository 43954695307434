
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, PropSync, Watch} from 'vue-property-decorator';
import {copy} from '@/utils/CopyToClipboard';
import Button from '@/components_v2/generic/input/__COMP_Button.vue';
import {callStore} from '@/store/__STORE_call';

@Component({
  components: {
    Button,
  },
})
/**
 * A component that renders a selection of buttons that demonstrates how a user might be able to join the room
 *
 * Changes view on wether the user is host or not
 */
export default class OtherWaysToJoin extends Vue {
  @PropSync('alias', {type: String}) readonly __PROPalias: string // alias of the VMR to be queried
  @PropSync('isHost', {type: Boolean}) readonly __PROPhost: boolean // changes the view, if the user is a host of the meeting. flag this as `true`
  @PropSync('toggle', {type: Boolean, default: true}) readonly __PROPtoggle: boolean // changes how the buttons should act. `true` only one is active at one time, `false` all buttons can be high at any one time
  @PropSync('clear', {type: Boolean, default: false}) readonly __PROPclear: boolean // clear the button feedback

  meetingUrl: string | null = null
  copyFailed = false

  buttonTracker = [
    {
      name: 'invite',
      show: false,
    },
    {
      name: 'teams',
      show: false,
    },
    {
      name: 'skype',
      show: false,
    },
    {
      name: 'phone',
      show: false,
    },
    {
      name: 'conference',
      show: false,
    },
    {
      name: 'xr',
      show: false,
    },
  ] // array to keep track of the currently active buttons

  /**
   * clear all button feedback
   */
  @Watch('clear')
  clearFeedback() {
    this.buttonTracker.forEach((el) => {
      el.show = false;
    });
  }

  /**
   * refresh the data on the page
   */
  @Watch('alias')
  refresh() {
    // reset data
    this.clearFeedback();
    this.copyFailed = false;
    // generate joining link from provided alias
    this.meetingUrl = `https://${window.location.hostname}/${this.__PROPalias}`;
  }

  /**
   * transisition between button presses
   *
   * @param {string} to
   */
  transition(to: string) {
    if (this.__PROPtoggle) {
      const lastKnownPressedButton = this.buttonTracker.find((el) => el.show == true);
      if (lastKnownPressedButton) lastKnownPressedButton.show = false;
      if (lastKnownPressedButton && lastKnownPressedButton.name == to) lastKnownPressedButton.show = false;
      else this.buttonTracker.find((el) => el.name == to).show = !this.buttonTracker.find((el) => el.name == to).show;
    } else {
      this.buttonTracker.find((el) => el.name == to).show = !this.buttonTracker.find((el) => el.name == to).show;
    }
  }

  /**
   *
   */
  mounted() {
    this.refresh();
  }

  /**
   * copies the meeting url to the users clipboard
   */
  async copyUrl() {
    if (!this.meetingUrl) return;
    else {
      const res = await copy(this.meetingUrl);

      if (res) this.$toast.success('Meeting url copied to your clipboard');
      else {
        this.copyFailed = true;
        this.$toast.error('Failed to copy the meeting url to your clipboard');
      }
    }
  }

  /**
   * Fires when one of the buttons was pressed
   *
   * @param {string} st
   * @return {string}
   */
  @Emit('show')
  __EMITButtonWasPressed(st: 'invite' | 'teams' | 'skype' | 'phone' | 'xr' | 'conference') {
    this.transition(st);
    return st;
  }

  /**
   * Dictates if the user has device compataible addons
   */
  get canConnectOnDevice() {
    if (callStore.vmr) {
      return callStore.vmr.has_phone_license || callStore.vmr.has_video_device_license || callStore.vmr.has_xr_license;
    }
    return false;
  }

  /**
   *
   */
  get hasPhoneLicense() {
    return callStore.vmr.has_phone_license;
  }
  /**
   *
   */
  get hasXrLicense() {
    return callStore.vmr.has_xr_license;
  }
  /**
   *
   */
  get hasDeviceLicense() {
    return callStore.vmr.has_video_device_license;
  }
}
