<template>
  <div
    v-if="!isProcessing && (!haveRequiredPermissions || (isVideoCall && !hasCamera && !confirmNoCamera))"
    class="auth-overlay"
  >
    <div>
      <div class="prompt-block">
        <template v-if="!haveRequiredPermissions">
          <div v-if="!isChromeDenied && isMozilla">
            <h4>
              We need access to your
              <template v-if="callType === 'video' && hasCamera">
                camera and
              </template>
              microphone.
            </h4>
            <p>
              Please click the camera blocked icon
              <img
                alt="SimplyVideo"
                src="@/assets/img/firefox-auth-camera.png"
              >
              in your address bar to fix this and refresh the page.
            </p>
          </div>

          <div
            v-if="!isChromeDenied && !isMozilla"
          >
            <h4>
              It appears that you haven't enabled this browser to use your
              <template v-if="callType === 'video' && hasCamera">
                camera and
              </template>
              microphone.
            </h4>
            <button
              @click="reattemptPerms"
            >
              Request Authorisation
            </button>
          </div>

          <div v-if="isChromeDenied">
            <h4>
              We need access to your
              <template v-if="callType === 'video' && hasCamera">
                camera and
              </template>
              microphone.
            </h4>
            <p>
              Please click the lock icon
              <img
                alt="SimplyVideo"
                src="@/assets/img/chrome-lock.png"
              >
              in your address bar to fix this and refresh the page.
            </p>
          </div>

          <div
            v-if="callType === 'video' && hasCamera"
            class="audio-switch"
          >
            Would you like to change to an audio only call?
            <button
              @click="changeCallType('audioonly')"
            >
              Change to Audio call
            </button>
          </div>
        </template>

        <div v-if="!hasCamera && !confirmNoCamera && callType === 'video'">
          <h4>We could not detect a camera.</h4>
          <p>You may proceed with a video call but you will not be seen.</p>
          <button
            class="btn-primary"
            @click="noCameraVideo()"
          >
            Proceed with Video call
          </button>
          <button
            @click="changeCallType('audioonly')"
          >
            Change to Audio call
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnumerateDevicesMixin from '@/mixins/EnumerateDevicesMixin';
import {callStore} from '@/store/__STORE_call';

export default {
  name: 'AuthorisationPrompt',
  mixins: [
    EnumerateDevicesMixin,
  ],
  props: {
    isConnecting: Boolean,
    initialCallType: {
      type: String,
      default: null,
    },
    hasCamera: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      callType: this.initialCallType,
      isChromeDenied: false,
      haveRequiredPermissions: false,
      cameras: [],
      confirmNoCamera: false,
      myinterval: null,
      isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      isMozilla: /Mozilla/.test(navigator.userAgent) && /Firefox/.test(navigator.userAgent),
      isProcessing: false,
    };
  },
  computed: {
    isVideoCall() {
      return this.callType === 'video';
    },
  },
  watch: {
    initialCallType() {
      this.callType = this.initialCallType;
      this.checkForDevices();
    },
    confirmNoCamera() {
      this.$emit('confirm-no-camera', this.confirmNoCamera);
    },
  },
  mounted() {
    navigator.mediaDevices.addEventListener('devicechange', (event) => {
      this.checkForDevices();
    });
    this.checkForDevices();
  },
  beforeDestroy() {
    clearInterval(this.myinterval);
  },
  methods: {
    reattemptPerms() {
      navigator.mediaDevices
          .getUserMedia({
            video: this.callType === 'video' && this.hasCamera,
            audio: true,
          })
          .then(
              (res) => {
                this.haveRequiredPermissions = true;
                this.isProcessing = false;
                // this.status = 'DONE'
              },
              (err) => {
                this.haveRequiredPermissions = false;
                this.isProcessing = false;
                this.isChromeDenied = false;
                // Something went wrong
                // if we can query the permissions then give that a try
                try {
                  if (navigator.permissions != undefined && this.isChrome) {
                    // audio
                    navigator.permissions
                        .query({name: 'microphone'})
                        .then((permissionObj) => {
                          this.isChromeDenied = true;
                        })
                        .catch((error) => {

                        });

                    // video
                    if (this.callType === 'video' && this.hasCamera) {
                      navigator.permissions
                          .query({name: 'camera'})
                          .then((permissionObj) => {
                            this.isChromeDenied = true;
                          })
                          .catch((error) => {
                            this.haveRequiredPermissions = false;
                          });
                    }
                  } else {
                    // cannot query or no bloack

                    this.haveRequiredPermissions = false;
                    this.isProcessing = false;
                  }
                } catch (e) {
                  // cannot query or no bloack

                  this.haveRequiredPermissions = false;
                  this.isProcessing = false;
                }
              },
          );
    },
    changeCallType(type) {
      callStore.setCallType(type);
    },
    checkForDevices() {
      this.isProcessing = true;
      this.enumerateDevices(() => {
        this.reattemptPerms();
      });
    },
    noCameraVideo() {
      this.confirmNoCamera = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .auth-overlay {
    background-color: rgb(0,0,0,0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
      display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }

  .prompt-block {
    max-width: 700px;
    margin: 30px auto 30px auto;
    max-height: 500px;
    display: inline-block;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    padding: 30px;
    border-radius: 15px;

    h4 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    p {
      margin: 1rem 0;
    }

    > div + div {
      border-top: 1px solid lightgrey;
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }

  .audio-switch {
    border-top: 1px solid #e5e5e5;
    margin-top: 15px;
    padding-top: 15px;
  }

  button {
    background:#e5e5e5;
    border: none;
    border-radius: 5px;
    display: inline-block;
    flex: 0 0 60px;
    padding: 5px 10px;
    vertical-align: middle;

    &:hover {
      background: darken(#e5e5e5, 10%);
    }
  }

  button + button {
    margin-left: 5px;
  }
</style>
