<template>
  <div
    class="apps-container"
  >
    <div class="header-container">
      <p>We recommend, for the best possible call experience, that you download our app <br> you can find the links to download it below</p>
    </div>
    <div
      class="links-container"
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.simplyvideo.companion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
      >
        <img
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        >
      </a>

      <a
        href="https://apps.apple.com/us/app/simplyvideo/id1538192708?itsct=apps_box&amp;itscg=30200"
        class="apple"
        target="_blank"
      >
        <img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1613001600&h=b5f790a5f75a8aa8961bb3fc6a1f64b8"
          alt="Download on the App Store"
        >
      </a>
    </div>

    <div class="bottom-container">
      <p>If you do not want to download the app then you are able to join on web, however this is a beta <br> feature and we cannot guarantee that everything will work perfectly.</p>
      <a
        class="color-link"
        @click.prevent="acceptTerms"
      >
        Accept & Continue
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAppLinks',
  methods: {
    acceptTerms() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .apps-container {
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    margin: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: centre;
    align-items: center;
    padding-top: 80px;
    box-sizing: border-box;

    .header-container {
      flex: 0 1 auto;
      padding: 20px 10px;

      p {
        font-weight: 400;
        text-align: center;
      }
    }

    .links-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        flex: 0 1 50%;
        width: 65vw;
      }

      .apple {
        border-radius: 13px;
        padding: 15px 15px;

        img {
          border-radius: 13px;
        }
      }
    }

    .bottom-container {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;

      p {
        font-weight: 400;
        text-align: center;
      }

      a {
        text-align: center;
      }
    }
  }
</style>
