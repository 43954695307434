export default {
  data() {
    return {
      isSpeedTested: false,
    };
  },
  methods: {
    speedTest(callback) {
      if (navigator && navigator.connection && navigator.connection.downlink) {
        const speedKbps = Math.round(navigator.connection.downlink * 1024);
        this.isSpeedTested = true;
        callback(speedKbps);
        return;
      }

      const imageAddr = '/img/speedtest.jpg';
      const downloadSize = 4995374; // bytes
      const startTime = (new Date()).getTime();
      const download = new Image();
      download.onload = function() {
        const endTime = (new Date()).getTime();
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = downloadSize * 8;
        const speedBps = Math.round(bitsLoaded / duration);
        let speedKbps = Math.round(speedBps / 1024);

        // Min 50
        speedKbps = speedKbps >= 50 ? speedKbps : 50;

        // Max 10000
        speedKbps = speedKbps <= 10000 ? speedKbps : 10000;

        this.isSpeedTested = true;
        callback(speedKbps);
      };

      download.src = imageAddr + '?cache=' + startTime;
    },
  },
};
