<template>
  <div class="action-cover">
    <div
      v-tooltip="enabled ? 'Hide settings' : 'Show settings'"
      :class="['circular', {'enabled': enabled}]"
      @click="$emit('toggle')"
    >
      <img
        v-if="enabled"
        src="@/assets/img/icons/ic_settings.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/__meeting_action";

  .circular {
    background: url('../../../assets/img/icons/ic_settings_white.svg') center center no-repeat transparent;
    background-size: auto 20px;
    border-radius: 50%;
    border: 1px solid white;
    height: 40px;
    width: 40px;

    &.enabled {
      background-color: rgba(0,0,0,0.5);
      border-color: var(--primary-color);
    }

    img {
      display: none;
    }
  }
</style>
