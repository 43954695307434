
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, PropSync, Watch} from 'vue-property-decorator';

@Component
/**
 * Generic button component
 */
export default class Button extends Vue {
  @PropSync('type', {type: String, default: 'primary'}) readonly __PROPtype: 'primary' | 'secondary' | 'toggle' // the type of button
  @PropSync('feedback', {type: Boolean, required: false}) readonly __PROPfb: boolean // toggle the buttons feedback
  @PropSync('independant', {type: Boolean, default: true}) readonly __PROPsolo: boolean // toggles wthere the button should manage it's own feedback or not

  private fb = false

  /**
   * Overrides the current button feedback status
   */
  @Watch('feedback')
  forceFeedback() {
    this.fb = this.__PROPfb;
  }

  /**
   *
   */
  mounted() {
    this.fb = this.__PROPfb;
  }

  /**
   * Fires when the button was pressed
   */
  @Emit('pressed')
  __EMITpressed() {
    if (this.__PROPsolo) {
      this.fb = !this.fb;
    }
  }
}
