
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync, Watch} from 'vue-property-decorator';

import {mainStore} from '@/store/__STORE_main';
import {callStore} from '@/store/__STORE_call';
import {brandingStore} from '@/store/__STORE_branding';

import QRCode from '@/components_v2/intelligent/branding/__COMP_QRCode.vue';

@Component({
  components: {
    QRCode,
  },
})
/**
 * Component to hold the hero branding image for the platform
 */
export default class HeroImage extends Vue {
  name: 'Hero Image'

  @PropSync('alias', {type: String, default: null}) readonly __PROPalias: string // alias of the room
  @PropSync('highlight', {type: Boolean, default: false}) readonly __PROPhighlight?: boolean // highlight QR state
  @PropSync('type', {type: String, default: 'xr_guest'}) readonly __PROPtype: 'xr_guest' | 'xr_host' // Type of QR code to generate

  private brand: any = {};
  backImageStyle: string = '';
  private defaultBkImage = require('@/assets/img/SV-rebrand-hero.png');

  /**
   * Getter to map the state from the call store into the comp
   */
  get meetingRoom() {
    return callStore.vmr;
  }

  /**
   * Overrides the background image when loaded
   */
  @Watch('meetingRoom', {deep: true})
  forceImageUpdate() {
    const bkImage = (this.meetingRoom.brand.hero_image ? this.meetingRoom.brand.hero_image : this.defaultBkImage);
    this.backImageStyle = `background-image: url(${bkImage})`;
  }


  /**
   * Run when the component is shown in its parent view
   * Will get the data required for the component to function
   */
  async mounted() {
    await callStore.getVMR(this.__PROPalias)
        .catch((error: any) => {
          this.onError(error);
        });
    mainStore.setLoading();
    this.brand = this.meetingRoom.brand;

    const bkImage = (this.brand.hero_image ? this.brand.hero_image : this.defaultBkImage);
    this.backImageStyle = `background-image: url(${bkImage})`;

    brandingStore.setLogo( {logo: this.brand.logo, level: this.brand.level});
    mainStore.setSuccess();
  }

  /**
   * If an error occurs this handles going back to dashboard with an error
   * @param {any} errorObject - the error object from the thrown error
   */
  onError(errorObject: any) {
    const statusCode = errorObject.response ? errorObject.response.status : 'undefined';
    const errorMsg = ('Unexpected error, code ' + statusCode);

    this.redirectBack(errorMsg);
  }

  /**
   * Function handles pushing back to the dashboard
   * @param {string} errorMsg - the error message string to display to the user
   */
  redirectBack(errorMsg: string) {
    this.$router.push({
      name: 'dashboard',
      params: {
        error: errorMsg || '',
      },
    });
  }

  /**
   * Getter to return whether the meeting room has an XR license addon
   * @return {boolean} - whether the room has an XR License
   */
  get meetingRoomHasXrLicense(): boolean {
    if (this.meetingRoom) {
      return this.meetingRoom.has_xr_license;
    }
    return false;
  }
}

